@import "~slick-carousel/slick/slick";
@import "~slick-carousel/slick/slick-theme";
@import "../../assets/css/variables.module";

.slick-dots {
  bottom: -25px;

  li {

    &.slick-active button:before {
      color: $lite;
    }

    button {
      font-size: 0;
      line-height: 0;
      display: block;
      width: 20px;
      height: 20px;
      padding: 5px;
      cursor: pointer;
      color: transparent;
      border: 0;
      outline: none;
      background: transparent;

      &:before {
        font-size: 10px;
        line-height: 20px;
        position: absolute;
        top: 0;
        left: 0;
        width: 20px;
        height: 20px;
        content: '•';
        text-align: center;
        color: #000;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        //border: 1px solid $lite;
        //border-radius: 50%;
      }
    }
  }
}

.slider-info {
  cursor: grab;
}
