@import "./variables.module.scss";

.about-description {
    color: $lite !important;
}

.reservationSection {
    background-color: $lite;
}

.backgroundDark {
    background-color: $background-light;
}

.about-title {
    font-family: "Carter One", sans-serif;
    font-size: 37px !important;
}

.category-name-font {
    font-family: "Cairo", sans-serif !important;
}

.reservation-title {
    font-family: "Carter One", sans-serif;
    font-size: 40px !important;
    color: $primary;
}

h3.title-big {
    font-family: "Carter One", sans-serif;
    font-size: 37px !important;
}

.fugaz-font {
    font-family: "Carter One", sans-serif;
    font-size: 37px !important;
}

.w3l-footer-29-main h6.footer-title-29 {
    font-family: "Carter One", sans-serif;
    font-size: 28px !important;
}

.exo2 {
    font-family: "Carter One", sans-serif;
    font-size: 40px !important;
}
.profile-padding {
    padding: 15px;
    font-size: 20px;
}
.profile-margin {
    margin-bottom: 5px;
}

.footer-text {
    color: $primary;
}

.food-price {
    color: $lite;
    font-size: 20px !important;
}

.food-title {
    text-align: center;
    color: $lite !important;
    padding: 10px;
    margin-top: 15px;
    margin-bottom: 15px;
}

.food-image {
    width: 350px;
    height: auto;
}

.food-background{
    background-color: $primary;
}

.reservation-hear-about-text {
    color: $primary !important;
    font-size: 20px;
    margin-top: 12px;
    font-weight: bold !important;
}

.point-text {
    margin-top: 100px !important;
    margin-bottom: 80px !important;
    font-size: 25px !important;
    color: $primary !important;
}

.point-value {
    font-size: 45px !important;
}

.point-link {
    font-size: 16px !important;
    margin-top: 20px !important;
}

.pop-over {
    color: $lite;
    cursor: pointer;
    font-size: large;
}

.pop-over-text{
    font-size: 18px !important;
    padding: 5px !important;
    
}
